
import { Options, Vue } from 'vue-class-component';
import { mapState } from 'vuex';

import requests from '@/requests';

import methods from '@/methods';

@Options({
  computed: {
    ...mapState(['data']),
  },
})
export default class ConfirmAccount extends Vue {
  public time = 0;

  public interval = -1;

  public timeout = -1;

  public confirmAccount = false;

  public errorConfirmAccount = false;

  public resend(startTimeout?: boolean): void {
    // reset - for reset password;
    // confirm - for confirm email after sign up;

    if (this.$route?.query?.type === 'reset') {
      requests.profile.resetPassword(this.$store.state.data)
        .finally(() => { if (startTimeout) this.timerForEnableResend(); });
    } else if (this.$route?.query?.type === 'confirm') {
      requests.profile.resendConfirmEmail(this.$store.state.data)
        .finally(() => { if (startTimeout) this.timerForEnableResend(); });
    }
  }

  public goBack(): void {
    this.$router.go(-1);
  }

  private timerForEnableResend(): void {
    clearInterval(this.interval);
    clearTimeout(this.timeout);

    this.time = 10;

    this.interval = setInterval(() => {
      this.time -= 1;
    }, 1000);

    this.timeout = setTimeout(() => clearInterval(this.interval), this.time * 1000);
  }

  private confirmAccountWithCode(): void {
    this.confirmAccount = true;

    requests.profile.confirmAccount({
      code: this.$route.query.code as string,
      email: this.$route.query.email as string,
    })
      .then((res) => {
        this.$store.commit('setupToken', { token: res.data.token, hijacking: false });

        requests.profile.getProfile().then((res) => {
          methods.store.setupProfile(res.data);
          this.$router.push('/dashboard');
        });
      })
      .catch(() => {
        this.$store.commit('changeData', this.$route.query.email);

        this.errorConfirmAccount = true;
      });
  }

  created(): void {
    if (this.$route?.query?.email && this.$route?.query?.code) {
      this.confirmAccountWithCode();
    } else if (this.$store.state.data && this.$route?.query?.type) {
      this.timerForEnableResend();
    } else {
      this.$router.push('/');
    }
  }

  unmounted(): void {
    this.$store.commit('changeData', null);
  }
}
